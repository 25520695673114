import React, { useEffect, useState } from "react";
import push_pin from "../../assets/images/push_pin.svg";
import { setPinnedSearches, setToaster } from "../../redux/actions";
import { addPinDocument, deletePinDocument, getAllPinnedDoc, getAllPinedMessage } from "../apiCall";
import { connect } from "react-redux";
import { Loader, Popup } from "semantic-ui-react"; // Importing Popup
import { getSasUrl } from "../apiCall";
import { appInsights } from "../../utils/appInsights.js";
import KeycloakUserService from "../../keycloak/KeycloakUserService";
import {pinDoc, unpinDoc } from "../../redux/actions";

const HeaderComponent = (props) => {
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [pinnedDocs, setPinnedDocs] = useState(props.pinnedSearches);
  const [disableButton1, setDisableButton1] = useState(false);
  const [disableButton2, setDisableButton2] = useState(false);
  const [loader, setLoader] = useState(false);
  const result = props.result || {};
  const equipment = result.equipment || [];
  const procedureDescription = result.procedureDescription || [];
  console.log('Info Type:', result);



  useEffect(() => {

    // getPinneddocument();
    
  }, []);


  function removeQueryString(url) {
    if (url.includes("?")) {
      return url.split("?")[0];
    }
    return url;
  }

  const categoryList = (categoryArray) => {
    if (!Array.isArray(categoryArray)) {
      return "";
    }
    return categoryArray.join(", ");
  };

  const redirectToPdf = (e, url) => {
    if (url !== "NO LINK") {
      e.preventDefault();
      setLoader(false);
      getSasUrl({
        url: removeQueryString(url),
        directory: props.docDetail.result.directory
          ? props.docDetail.result.directory
          : "",
      })
        .then((res) => {
          setLoader(false);
          !props.dualDoc && window.open(res.data.convertedUrl, "_blank");
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const pinTheDoc = (result) => {
    setApiCallInProgress(true);
    let pindoc = {
      ownerId: props.user,
      documentId: result.id,
      searchResults: result,
    };

    addPinDocument(pindoc)
      .then((data) => {
        setPinnedDocs((allReadyPinnedDoc) => [
          ...allReadyPinnedDoc,
          data.data.data.pindocumentId,
        ]);
        props.setPinnedSearches([
          ...pinnedDocs, data.data.data.pindocumentId
        ]);
        setApiCallInProgress(false);
        appInsights.trackEvent({
          name: "PinDocument",
          properties: {
            pinId: data.data.data.pindocumentId.pinId,
            documentId: data.data.data.pindocumentId.documentId,
            title: data.data.data.pindocumentId.searchResults.procedureTitle,
            username: data.data.data.pindocumentId.ownerId,
          },
        });
      })
      .catch(() => {
        setToaster(true);
      });
  };

  const unpindoc = (pinId) => {
    setApiCallInProgress(true);
    deletePinDocument(pinId)
      .then(() => {
        setPinnedDocs((result) =>
          result.filter((item) => item.documentId !== pinId)
        );
        props.setPinnedSearches(
          pinnedDocs.filter((item) => item.documentId !== pinId)
        );

        // props.unpinDoc(pinId);
        setApiCallInProgress(false);
        appInsights.trackEvent({
          name: "UnpinDocument",
          properties: {
            pinId: pinId,
            username: KeycloakUserService.getUsername(),
          },
        });
      })
      .catch(() => {
        setToaster(true);
      });
  };

  const pinButtonClick = (result) => {
    if (pinnedDocs.length < 10) {
      pinTheDoc(result);
      setDisableButton1(true);
      setDisableButton2(false);
    } else {
      alert(
        "You have reached the maximum limit of pinned documents. Please unpin some documents before pinning new ones"
      );
    }
  };

  const unPinButtonClick = (pinId) => {
    setDisableButton1(false);
    setDisableButton2(true);
    unpindoc(pinId);
  };

  const getType = (type) => {
    console.log('Type:', type);
    if (type === "SETUP INSTRUCTION") {
      return "Setup Instruction";
    } else if (type === "OPERATING PROCEDURES") {
      return "Operating Procedure";
    }
    if (type === "setupInstruction") {
      return "Setup Instruction";
    } else if (type === "operatingProcedure") {
      return "Operating Procedure";
    } else if (type === "PROMOTIONAL MATERIAL") {
      return "Promotional Material";
    } else if (type === "TECHNICAL NOTE") {
      return "Techical Note";
    } else {
      return type;
    }
  };

  return (
    <div>
      <div className="mt-[-1%] p-5 bg-white border border-gray-300 rounded-md">
        <div className="flex justify-between">
          <div>
            <div className="font-semibold mb-4">{props.title}</div>
            <div className="flex">
              <div className=" p-1 pl-4 pr-4 rounded-3xl bg-[#FDF0E4] w-fit border-2 text-[#f48728] border-[#f48728] text-sm">
                Category :{" "}
                {result.document_type === "authored"
                  ? result?.equipment
                  : categoryList(equipment || [result?.equipment])}
              </div>
              <div className="ml-3 p-1 pl-4 pr-4 rounded-3xl bg-[#FDF0E4] w-fit border-2 text-[#f48728] border-[#f48728] text-sm">
                Knowledge Source: {getType(result.documentType || result.type)}
              </div>
            </div>
            <div className="mt-3 p-1 pl-4 pr-4 rounded-3xl bg-blue-100 w-fit border-2 text-[#0971CE] border-[#0971CE] text-sm flex align-middle">
              <span className="material-symbols-outlined text-[20px]">link</span>
              <Popup
                content="View Original PDF File"
                position="top center"
                inverted
                trigger={
                  <span className="ml-3">
                    Original file:
                    {loader ? (
                      <Loader size="small" active inline />
                    ) : (
                      <a
                        className={
                          props.fileLink === "NO LINK" || props.dualDoc
                            ? "cursor-text"
                            : `underline text-[10px] md:text-[12px]`
                        }
                        href="/searchResult"
                        onClick={(e) =>
                          redirectToPdf(
                            e,
                            props.docDetail.result.file_link
                              ? props.docDetail.result.file_link
                              : ``
                          )
                        }
                      >
                        {result.procedureTitle
                          ? "NO FILE AVAILABLE"
                          : props.docDetail.result.file_name}
                      </a>
                    )}
                  </span>
                }
              />
            </div>
          </div>

          {pinnedDocs?.find((item) => item.documentId === props.result.id) ? (
            <Popup
              content="Unpin Document"
              position="top left"
              inverted
              trigger={
                <div className="pinImage mt-[-1.4rem]">
                  <button
                    className="ml-auto w-8 h-8"
                    disabled={disableButton2 || apiCallInProgress}
                    onClick={() =>
                      unPinButtonClick(
                        pinnedDocs?.find(
                          (item) => item.documentId === props.result.id
                        ).documentId
                      )
                    }
                  >
                    <img
                      className="h-7 m-[2px] rounded-full bg-[#1f97dc] absolute right-[1em] md:right-[2em]"
                      src={push_pin}
                      alt="push pin logo"
                    />
                  </button>
                  {disableButton2 && (
                    <div className="header-loader-pin">
                      <Loader active inline />
                    </div>
                  )}
                </div>
              }
            />
          ) : (
            <Popup
              content="Pin Document"
              position="top left"
              inverted
              trigger={
                <div className="pinImage mt-[-1.4rem]">
                  <button
                    className="ml-auto w-8 h-8  unpindoc"
                    disabled={disableButton1 || apiCallInProgress}
                    onClick={() => pinButtonClick(props.result)}
                  >
                    <img
                      className="h-7 m-[2px]  rotate-45 rounded-full bg-gray-200 absolute right-[1em] md:right-[2em] "
                      src={push_pin}
                      alt="push pin logo"
                    />
                  </button>
                  {disableButton1 && (
                    <div className="header-loader-pin">
                      <Loader active inline />
                    </div>
                  )}
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pinnedSearches: state.pinnedSearches,
    docDetail: state.docDetail,
  };
};

export default connect(mapStateToProps, {
  setToaster,
  setPinnedSearches,
  pinDoc,
  unpinDoc
})(HeaderComponent);