import React, { useEffect, useState } from "react";
import {
  pinDoc,
  saveSearchResult,
  saveSearchWord,
  saveSearchResultAuthDocs,
  setActivePage,
  setPinnedSearches,
  setToaster,
  setTotalDoc,
  
} from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { savefilteroplength } from "../../redux/actions";
import { connect } from "react-redux";
import { Pagination, Loader } from "semantic-ui-react";
import { searchApi, addPinDocument, deletePinDocument, getAllPinedMessage, getAllPinnedDoc } from "../apiCall";
import KeycloakUserService from "../../keycloak/KeycloakUserService.js";
import IngResultDisplay from "./IngResultDisplay";
import { appInsights } from "../../utils/appInsights.js";

import { v4 as uuidv4 } from 'uuid';

const SearchResultBox = (props) => {
  const perPage = 10;
  const results = 10;
  let JSONParse = props.searchResult && JSON.parse(props.searchResult);
  let JSONParseAuthDocs =
    props.searchResultAuthDocs && JSON.parse(props.searchResultAuthDocs);
  const [searchResult, setSearchResult] = useState(JSONParse || null);
  const [searchResultAuthDocs, setSearchResultAuthDocs] = useState(
    JSONParseAuthDocs || null
  );
  const [featureFlag, setFeatureFlag] = useState(props.featureFlag);
  const [pinnedDocs, setPinnedDocs] = useState(props.pinnedSearches);
  const [loading, setLoading] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const navigate = useNavigate();

  const getPinneddocument = async () => {
    
    let docfrommongo = [];
    let docfromsolr = [];
    
    try {
      const getAllPinedMessageRes = await getAllPinedMessage();
      console.log("getAllPinedMessageRes", getAllPinedMessageRes);
      const idArray = getAllPinedMessageRes.data.data.map(
        (item) => item.documentId
      );
      docfrommongo = getAllPinedMessageRes.data.data;
      const query = {
        q: idArray,
        filter: [],
      };
      const getAllPinnedDocRes = await getAllPinnedDoc(query);
      console.log("getAllPinnedDocRes", getAllPinnedDocRes);
      docfromsolr = getAllPinnedDocRes.data.response.docs;
      docfrommongo.map((data) => {
        let pindata = docfromsolr.filter((obj) => obj.id === data.documentId);
        console.log("pindata", pindata);
        data.searchResults = pindata[0];
      });
      console.log("docfrommongo", docfrommongo);
      setPinnedDocs(docfrommongo);
      props.setPinnedSearches(docfrommongo);
      
    } catch (err) {
      props.setToaster(true);
    }

  };

  useEffect(() => {
    // getPinneddocument();
  }, []);

  const pinTheDoc = (result) => {
    setApiCallInProgress(true);
    let pindoc = {
      documentId: result.id,
      searchResults: result,
    };

    addPinDocument(pindoc)
      .then((data) => {
        setPinnedDocs((allReadyPinnedDoc) => [
          ...allReadyPinnedDoc,
          data.data.data.pindocumentId,
        ]);
        props.setPinnedSearches(
          [...pinnedDocs, data.data.data.pindocumentId].reverse()
        );
        setApiCallInProgress(false);
        appInsights.trackEvent({ 
            name: "PinDocument", 
            properties: { 
              pinId: data.data.data.pindocumentId.pinId,
              documentId: data.data.data.pindocumentId.documentId,
              title: data.data.data.pindocumentId.searchResults.procedureTitle,
              username: data.data.data.pindocumentId.ownerId
            } 
        });
      })
      .catch((err) => {
        alert(err)
      });
  };

  const unpindoc = (pinId) => {
    setApiCallInProgress(true);
    deletePinDocument(pinId)
      .then(() => {
        setPinnedDocs((result) =>
          result.filter((item) => item.documentId !== pinId)
        );
        props.setPinnedSearches(
          pinnedDocs.filter((item) => item.documentId !== pinId).reverse()
        );
        setApiCallInProgress(false);
        appInsights.trackEvent({ 
            name: "UnpinDocument", 
            properties: { 
              pinId: pinId,
              username: KeycloakUserService.getUsername() 
            } 
        });
      })
      .catch(() => {
        props.setToaster(true);
      });
  };

  let getFirstTwoSentence = (data) => {
    let sentences = data.split(". ");
    let returnSummary = "";
    do {
      returnSummary += sentences[0] += ". ";
      sentences.shift();
    } while (returnSummary.length < 500 && sentences.length);

    if (returnSummary.length > 500) {
      let words = returnSummary.split(/\s+/);
      returnSummary = words.slice(0, 70).join(" ");
      returnSummary += "...";
    }

    return returnSummary;
  };

  useEffect(() => {
    
    let JSONParseafter = JSON.parse(props?.searchResult);
    setSearchResult(JSONParseafter);
    if (
      props &&
      props.searchResultAuthDocs &&
      props.searchResultAuthDocs.length
    ) {
      let authJSONParseafter = JSON.parse(props?.searchResultAuthDocs);
      setSearchResultAuthDocs(authJSONParseafter);
    } else {
      setSearchResultAuthDocs(null);
    }
  }, [props?.searchResult, props?.searchResultAuthDocs]);

  useEffect(() => {
    if (Object.keys(JSON.parse(props.newFilter)).length > 0) {
      getDocs(props.activePage * results - results);
    }
  }, [props.newFilter]);

  function handleSessionOut() {
    if (KeycloakUserService.isTokenExpired()) {
      navigate("/");
      props.saveSearchWord("");
      localStorage.clear();
      window.location.reload(false);
      KeycloakUserService.doLogout();
    }
  }

  const getDocs = async (start) => {
    handleSessionOut();
    setLoading(true);
    var queryToExecute;
    let filterData = [];

    let filteringdataprop = JSON.parse(props?.newFilter);
    if (filteringdataprop?.type && filteringdataprop?.type !== "") {
      filterData.push({
        field: "type",
        value: filteringdataprop.type,
      });
    }
    if (filteringdataprop?.equipment && filteringdataprop?.equipment !== "") {
      filterData.push({
        field: "equipment",
        value: filteringdataprop.equipment,
      });
    }
    if (props.searchedWord !== "" && props.searchedWord !== undefined) {
      
      queryToExecute = {
        q: props.searchedWord,
        rows: 10,
        start: start !== undefined ? start : 0,
        filter: filterData,
        searchId: props.searchId,
      };
      
      
      await searchApi(queryToExecute)
        .then((res) => {
          
          props.saveSearchResult(JSON.stringify(res.data.response.docs));
          res.data.response?.authDocs?.length > 0
            ? props.saveSearchResultAuthDocs(
                JSON.stringify(res.data.response.authDocs)
              )
            : props.saveSearchResultAuthDocs("");
             
          setLoading(false);
          props.setTotalDoc(res.data.response.numFound);
          appInsights.trackEvent({ 
              name: "search", 
              properties: { 
                q: queryToExecute,
                filters: filterData,
                username: KeycloakUserService.getUsername() 
              } 
          });

          navigate("/searchResult");
        })
        .catch((error) => {
          setLoading(false);
          if (
            error.response?.data === "Access denied" ||
            error.response?.status === 403
          ) {
          }
        });
    }
  };

  function pageChange(e, data) {
    props.setActivePage(data.activePage);
    window.scrollTo(0, 0);
    getDocs(data.activePage * results - results);
  }

  return (
    <>
      {loading ? (
        <div
          className={`border shadow-md rounded-md ml-14 mr-14 mt-5 w-none ${
            featureFlag["Q&A engine"] ? "md:w-[58.34em]" : "md:w-[83.34em]"
          }`}
        >
          <div className="loader">
            <Loader active inline />
          </div>
        </div>
      ) : (
        <div>
          <div>
            {props.totalDoc === 0 && (
              <div
                className="border shadow-md rounded-md ml-14 mr-14 mt-6  w-none
                md:w-[82.34em]"
              >
                No Result found
              </div>
            )}
            <div>
              {searchResult?.map((result) => {
                
                return (
                  <IngResultDisplay
                  
                    key={result.id}
                    result={result}
                    pinTheDoc={pinTheDoc}
                    unpindoc={unpindoc}
                    getFirstTwoSentence={getFirstTwoSentence}
                    pinnedDocuments={pinnedDocs}
                    apiCallInProgress={apiCallInProgress}
                  />
                );
              
              })}
            </div>
          </div>
          {JSON.parse(props.searchResult).length > 0 && (
            <div className="pagination-container">
              <Pagination
                activePage={props.activePage}
                onPageChange={(e, data) => pageChange(e, data)}
                totalPages={Math.ceil(JSON.parse(props.totalDoc) / perPage)}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    knowledgeSrcFilter: state.knowledgeSrcFilter,
    relatedEquipmentFilter: state.relatedEquipmentFilter,
    searchedWord: state.searchedWord,
    searchResult: state.searchResult,
    searchResultAuthDocs: state.searchResultAuthDocs,
    activePage: state.activePage,
    totalDoc: state.totalDoc,
    pinnedSearches: state.pinnedSearches,
    newFilter: state.newFilter,
    feedbackList: state.feedbackList,
    featureFlag: state.featureFlag,
    searchId: state.searchId,
  };
};

export default connect(mapStateToProps, {
  pinDoc,
  savefilteroplength,
  saveSearchResult,
  saveSearchWord,
  saveSearchResultAuthDocs,
  setActivePage,
  setToaster,
  setTotalDoc,
  setPinnedSearches,
  
  })(SearchResultBox);