import React, { useState } from "react";
import KeycloakUserService from "../../keycloak/KeycloakUserService";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { saveSearchWord, setPinnedSearches } from "../../redux/actions";

const UserBtns = ({ closeDropdown, ...props }) => {
  const [btn, setBtn] = useState(0);
  const navigate = useNavigate();

  function setBgBtn(num) {
    setBtn(num);
    navigate("/allpinnedsearches");
    closeDropdown(); // Close dropdown after navigation
  }

  function logout() {
    navigate("/");
    localStorage.clear();
    props.saveSearchWord("");
    props.setPinnedSearches([]);
    sessionStorage.clear();
    KeycloakUserService.doLogout();
    closeDropdown(); // Close dropdown after logout
  }

  return (
    <div className="absolute top-[3.6rem] md:top-[4.8rem] right-[1.6rem] md:right-[1rem] flex flex-col h-[60px] md:h-[80px] w-[135px] md:w-[210px] pt-1 md:pt-3 bg-white text-xs rounded-xl border drop-shadow mt-1 bg-white z-20">
      <div
        onClick={() => setBgBtn(1)}
        className={`${
          props.newPath === "/allpinnedsearches"
            ? "userOptionsBtnsClicked text-sky-700"
            : ""
        }`}
      >
        <span className="material-symbols-outlined userOptionsBtns ml-5 text-[10px] md:text-[12px]">
          search
        </span>
        <span className="ml-[-2px] md:ml-2 font-inter text-[8px] md:text-[12px]">
          Pinned Searches
        </span>
      </div>
      <div
        onClick={() => logout()}
        className={`${
          btn === 4 ? "userOptionsBtnsClicked text-sky-700" : ""
        } mt-[-8px] md:mt-[-2px]`}
      >
        <span className="material-symbols-outlined userOptionsBtns ml-5 text-[10px] md:text-[12px]">
          logout
        </span>
        <span className="ml-[-2px] md:ml-2 font-inter text-[8px] md:text-[12px]">
          Logout
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  newPath: state.newPath,
});

export default connect(mapStateToProps, {
  saveSearchWord,
  setPinnedSearches,
})(UserBtns);
